<template>
  <v-dialog v-model="trigger" persistent scrollable max-width="800px">
    <v-card>
      <v-app-bar flat color="primary">
        <v-toolbar-title class="title white--text pl-0">
          {{ this.itemData.firstName }} {{ this.itemData.lastName }}
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn color="white" icon @click="$emit('closeModal')">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-app-bar>

      <v-card-text>
        <v-row>
          <v-col cols="12">
            <Patcher
              :itemValue="itemData.title"
              itemName="title"
              itemLabel="Name des Events"
              :hideLabel="false"
              :route="route"
              inputElement="input"
              inputType="text"
              @emit="itemData.title = $event.value"
            ></Patcher>

            <!-- custom_text_booking_thankyou (Text, MD)*
custom_text_confirmation_email_subject (Text, MD)
custom_text_confirmation_email_body (Text, MD) -->

            <Patcher
              :itemValue="itemData.custom_text_booking_thankyou"
              itemName="custom_text_booking_thankyou"
              itemLabel="Dankestext nach Buchung"
              :hideLabel="false"
              :route="route"
              inputElement="textarea-md"
              inputType="text"
              @emit="itemData.custom_text_booking_thankyou = $event.value"
              hint="Platzhalter: %eventcalendar-booked-title% (Eventtitel),<br/>
%eventcalendar-booked-date% (Datum),<br/>
%eventcalendar-booked-starttime% (Startzeit),<br/>
%eventcalendar-booked-endtime% (Endzeit),<br/>
%eventcalendar-timeslot-thankyou-addition% (Danketextzusatz)"
            ></Patcher>

            <Patcher
              :itemValue="itemData.custom_text_confirmation_email_subject"
              itemName="custom_text_confirmation_email_subject"
              itemLabel="Email-Betreff"
              :hideLabel="false"
              :route="route"
              inputElement="input"
              inputType="text"
              @emit="
                itemData.custom_text_confirmation_email_subject = $event.value
              "
            ></Patcher>

            <Patcher
              :itemValue="itemData.custom_text_confirmation_email_body"
              itemName="custom_text_confirmation_email_body"
              itemLabel="Email-Text"
              :hideLabel="false"
              :route="route"
              inputElement="textarea-md"
              inputType="text"
              @emit="
                itemData.custom_text_confirmation_email_body = $event.value
              "
              hint="Platzhalter: %eventcalendar-booked-title% (Eventtitel),<br/>
%eventcalendar-booked-date% (Datum),<br/>
%eventcalendar-booked-starttime% (Startzeit),<br/>
%eventcalendar-booked-endtime% (Endzeit),<br/>
%eventcalendar-timeslot-mail-addition% (Mailtextzusatz)"
            ></Patcher>
          </v-col>
          <v-col cols="12">
            <EventTimeslots :eventId="id"></EventTimeslots>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import Patcher from "@/components/ui/Patcher.vue";
import EventTimeslots from "@/components/companies/event_calendar/EventTimeslots.vue";
export default {
  name: "UpdateEvent",

  components: {
    Patcher,
    EventTimeslots,
  },

  props: ["trigger", "itemData", "id"],

  data() {
    return {
      title: "",
      route:
        "companies/" +
        this.$route.params.id +
        "/addons/eventcalendar/events/" +
        this.itemData.id,
    };
  },
};
</script>
