<template>
  <v-dialog v-model="trigger" persistent scrollable max-width="800px">
    <v-card>
      <v-app-bar flat color="primary">
        <v-toolbar-title class="title white--text pl-0">
          {{ this.itemData.firstName }} {{ this.itemData.lastName }}
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn color="white" icon @click="$emit('closeModal')">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-app-bar>

      <v-card-text>
        <v-row>
          <v-col cols="12">
            <Patcher
              :itemValue="itemData.eventDate"
              itemName="eventDate"
              itemLabel="Datum des Events"
              :hideLabel="false"
              :route="route"
              inputElement="datepicker"
              inputType="date"
              @emit="itemData.eventDate = $event.value"
            ></Patcher>
          </v-col>

          <v-col cols="12">
            <Patcher
              :itemValue="itemData.startTime"
              itemName="startTime"
              itemLabel="Startzeit"
              :hideLabel="false"
              :route="route"
              inputElement="input"
              inputType="time"
              @emit="itemData.startTime = $event.value"
            ></Patcher>
          </v-col>

          <v-col cols="12">
            <Patcher
              :itemValue="itemData.endTime"
              itemName="endTime"
              itemLabel="Endzeit"
              :hideLabel="false"
              :route="route"
              inputElement="input"
              inputType="time"
              @emit="itemData.endTime = $event.value"
            ></Patcher>
          </v-col>

          <v-col cols="12">
            <Patcher
              :itemValue="itemData.maxSeats"
              itemName="maxSeats"
              itemLabel="Maximale Plätze"
              :hideLabel="false"
              :route="route"
              inputElement="input"
              inputType="number"
              @emit="itemData.maxSeats = $event.value"
            ></Patcher>
          </v-col>

          <v-col cols="12">
            <Patcher
              :itemValue="itemData.custom_text_confirmation_mail_addition"
              itemName="custom_text_confirmation_mail_addition"
              itemLabel="Email-Text Zusatz"
              :hideLabel="false"
              :route="route"
              inputElement="textarea-md"
              inputType="text"
              @emit="
                itemData.custom_text_confirmation_mail_addition = $event.value
              "
              hint="Mit diesem Inhalt wird die E-Mail ergänzt."
            ></Patcher>
          </v-col>

          <v-col cols="12">
            <Patcher
              :itemValue="itemData.custom_text_booking_thankyou_addition"
              itemName="custom_text_booking_thankyou_addition"
              itemLabel="Dankes-Text Zusatz"
              :hideLabel="false"
              :route="route"
              inputElement="textarea-md"
              inputType="text"
              @emit="
                itemData.custom_text_booking_thankyou_addition = $event.value
              "
              hint="Mit diesem Inhalt wird des Dankes-Text ergänzt."
            ></Patcher>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import Patcher from "@/components/ui/Patcher.vue";
export default {
  name: "UpdateTimeslot",

  components: {
    Patcher,
  },

  props: ["trigger", "itemData", "id"],

  data() {
    return {
      title: "",
      route:
        "companies/" +
        this.$route.params.id +
        "/addons/eventcalendar/events/" +
        this.itemData.id +
        "/timeslots/" +
        this.id,
    };
  },
};
</script>
