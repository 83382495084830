<template>
  <div id="page">
    <PageHeader
      v-if="!preloader"
      :pageTitle="title + ' - ' + pageTitle"
      :pageDescription="pageDescription"
    ></PageHeader>
    <v-row>
      <v-col cols="8" v-if="!preloader">
        <DataTable
          @tableAction="emittedAction"
          :items="items"
          :headers="headers"
          :itemActions="{
            edit: true,
            trash: true,
          }"
          :disableSearch="true"
          :preloader="preloader"
        />
      </v-col>
      <v-col cols="8" v-else> </v-col>
      <v-col cols="4">
        <Subnavigation
          root="/csm/company-manager/:id"
          :pageTitle="pageTitle"
        ></Subnavigation>
      </v-col>
    </v-row>

    <CreateEvent
      :trigger="createItem"
      @closeModal="createItem = false"
      @emit="emittedAction"
    />

    <UpdateEvent
      v-if="editItem && items[itemIndex].id"
      :trigger="editItem"
      :itemData="itemData"
      :id="itemId"
      @closeModal="editItem = false"
    ></UpdateEvent>

    <v-btn fixed dark fab bottom right color="primary" @click="addItem">
      <v-icon>mdi-plus</v-icon>
    </v-btn>
  </div>
</template>

<script>
import PageHeader from "@/components/ui/PageHeader.vue";
import Subnavigation from "@/components/ui/Subnavigation.vue";
import DataTable from "@/components/ui/DataTable.vue";
import CreateEvent from "@/components/companies/event_calendar/CreateEvent.vue";
import UpdateEvent from "@/components/companies/event_calendar/UpdateEvent.vue";
import apiRequest from "@/mixins/apiRequest";
export default {
  name: "CompanyEvents",
  components: {
    PageHeader,
    Subnavigation,
    DataTable,
    CreateEvent,
    UpdateEvent,
  },
  data() {
    return {
      pageTitle: "Events",
      pageDescription: "Hier können Sie die Events des Unternehmens verwalten.",
      title: "",
      preloader: false,
      itemId: "",
      itemIndex: "",
      itemData: "",
      editItem: false,
      createItem: false,
      items: [],
      headers: [
        { text: "ID", value: "id", sortable: false, width: "20px" },
        { text: "Titel", value: "title", sortable: false },
        {
          text: "",
          value: "actions",
          sortable: false,
          align: "right",
          width: "50px",
        },
      ],
    };
  },

  created: function () {
    this.getData(); // call it immediatly
  },

  methods: {
    async getData() {
      this.preloader = true;
      let response = await this.getRequest(
        "companies/" + this.$route.params.id + "/addons/eventcalendar/events"
      );
      this.items = response.data.data;

      /* Fetching Companyname for Headline */
      /* USEING REUSEABLE MIXIN METHOD */
      response = await this.getRequest("companies/" + this.$route.params.id); // await data from mixin
      this.title = response.data.data[0].name;

      this.preloader = false;
    },
    emittedAction(value) {
      if (value.action === "editItem") {
        this.itemId = value.itemId;
        this.itemIndex = value.itemIndex;
        this.itemData = this.items[value.itemIndex];
        this.editItem = true;
      } else if (value.action === "createdItem") {
        this.items.push(value.newPost);
        this.createItem = false;
      } else if (value.action === "deleteItem") {
        this.deletePrompt(value.itemId); // reload
      } else if (value.action === "closeModal") {
        this.createItem = false;
        this.editItem = false;
      } else if (value.action === "itemPatched") {
        this.getData(); // reload
      }
    },
    addItem() {
      this.createItem = true;
    },
    async deletePrompt(id) {
      const userChoice = await this.$dialog.confirm({
        text: "Soll das Event wirklich gelöscht werden?",
        title: "Achtung!",
        waitForResult: true,
      });

      if (userChoice) {
        // okay
        /* USEING REUSEABLE MIXIN METHOD */
        this.preloader = true;
        let response = await this.deleteRequest(
          "companies/" +
            this.$route.params.id +
            "/addons/eventcalendar/events/" +
            id
        ); // await data from mixin
        this.preloader = false;

        if (response) {
          this.$root.snackbarGlobal.snackbar = true;
          this.$root.snackbarGlobal.snackbarText = "Eintrag wurde gelöscht";
          this.$root.snackbarGlobal.errorMessage = "";
          this.$root.snackbarGlobal.snackbarColor = "primary";
          this.$root.snackbarGlobal.snackbarTimeout = 5000;
          this.getData(); // reload
        }
      } else {
        // abort
        this.$root.snackbarGlobal.snackbar = true;
        this.$root.snackbarGlobal.snackbarText = "Vorgang abgebrochen!";
        this.$root.snackbarGlobal.errorMessage = "";
        this.$root.snackbarGlobal.snackbarColor = "primary";
        this.$root.snackbarGlobal.snackbarTimeout = 5000;
      }
    },
  },

  mixins: [apiRequest],
};
</script>

<style>
.note p {
  margin-bottom: 0 !important;
}
</style>
